import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"

function About({data, location}) {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About" />

      <Bio />

      <p>Hello 🙋‍♂️</p>
      <p>I have been developing softwares for a little over a score years. I created this site to organize my thoughts in the engineering practice.</p>
      <p style={{
        fontSize: 'small'
      }}>This site is created with Gatsby + JavaScript + React + React Bootstrap and hosted at Netlify.</p>
    </Layout>
  );
}

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`