/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
            quote
          }
          social {
            twitter,
            github,
            linkedin
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  let authorInfo = null;
  let socialmedia = null;
  if (author) {
    authorInfo = (
      <div
        className="bio__detail">
        <p>
          <strong>{author.name}</strong> {author.summary}
        </p>
        <p
          className="bio__quote"
        >
          {author.quote}
        </p>
      </div>
    );
  }

  console.log(social);

  if (social) {
    const links = [];
    const linkImageSize = 24;

    if (social.linkedin) {
      links.push(
        <a
          href={`https://www.linkedin.com/in/${social.linkedin}/`}
          rel='nofollow noreferrer'
          target='_blank'
          title='Connect with me on LinkedIn'
          >
          <StaticImage
            src='../images/LI-In-Bug.png'
            alt='linked in logo'
            width={linkImageSize}
            height={linkImageSize}
            />
        </a>
      )
    }

    if (social.github) {
      links.push(
        <a
          href={`https://github.com/${social.github}/`}
          rel='nofollow noreferrer'
          target='_blank'
          title='Follow me on GitHub'
          >
          <StaticImage
            src='../images/GitHub-Mark-32px.png'
            alt='github logo'
            width={linkImageSize}
            height={linkImageSize}
          />
        </a>
      )
    }

    if (links.length > 0) {
      socialmedia = (
        <div 
          class="bio__socialmedia">
          {links}
        </div>
      )
    }
  }

  return (
    <div className="bio">
      <div
        className='bio__main'
      >
        <StaticImage
          className="bio-avatar"
          layout="fixed"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../images/profile-pic.jpg"
          width={50}
          height={50}
          quality={95}
          alt="Profile picture"
        />
        <div>
          <div>
            {authorInfo}
          </div>

          {socialmedia}
        </div>
      </div>
    </div>
  )
}

export default Bio
